<template>
  <main>
      <header-navigation title="add a todo" />

      <form @submit="send" class="form">
          <form-input name="title" :max="120" type="text" placeholder="rent has to be paid" label="what is there to do?" :error="titleError" :errors="titleErrors"  />
          <form-input name="date" type="date" label="when is it to do?" :error="dateError" :errors="dateErrors"  />
          <form-input name="description" type="description" label="any notes?" placeholder="get money" :error="descriptionError" :errors="descriptionErrors"  />        
      
          <button-input id="sub-todo" placeholder="sub-todo" label="any sub-todos?" @submit="addSubTodo" />

          <div v-if="sub_todos.length > 0" class="sub-todo-box-wrapper">
              <div v-for="(sub_todo, i) in sub_todos" :key="i" class="sub-todo-box">
                  <button @click="deleteSubTodo" :id="'sub-'+i">
                      <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                  </button>
                  <p>
                      {{ sub_todo }}
                  </p>
              </div>
          </div>

          <select-input id="folder" :selectedChecker="folder_id" selectedSelector="id" label="what folder?" :items="folders" placeholder="select a folder" valueSelector="id" textSelector="title" @change="changeFolder" />
          
          <button type="submit">
              save todo
          </button>
      </form>
  </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import ButtonInput from "@/components/ButtonInput.vue";
import SelectInput from "@/components/SelectInput.vue";
import { put, get } from "@/api.js";

export default {
name: 'EditTodo',

components: {
  HeaderNavigation, FormInput, ButtonInput, SelectInput
},

props: ['id'],

data() {
  return {
      titleError: false,
      titleErrors: [],
      dateError: false,
      dateErrors: [],
      descriptionError: false,
      descriptionErrors: [],

      sub_todos: [],
      sub_todo: '',

      folder_id: 6,
      folders: [],
  }
},

mounted() {
  this.folder_id = this.$props.folder
  this.getFoldersAndTodo()
},

methods: {
  async send(ev) {
      ev.preventDefault()
      try {
          let subs = (this.sub_todos.length > 0) ? this.sub_todos.map(sub_todo => {return sub_todo}).join('§'):''
          let response = await put('todo/' + this.$props.id, {
              title: document.getElementById('title').value,
              date: document.getElementById('date').value,
              description: document.getElementById('description').value,
              folder_id: this.folder_id,
              sub_todos: subs
          })
          if (response)
            this.$router.go(-1)
      } catch(error) {
          console.log(error)
      }
  },
  addSubTodo(value) {
      if (typeof value === typeof 'string')
          this.sub_todos.unshift(value)
  },
  deleteSubTodo(ev) {
      ev.preventDefault()
      this.sub_todos.splice(this.sub_todos.indexOf(this.sub_todos[ev.currentTarget.id.split('-')[1]]), 1)
  },
  async getFoldersAndTodo() {
      try {
          let todo = await get('todo/' + this.$props.id)
          let folders = await get('folders')
          this.folders = folders.folders

          this.folder_id = todo.todo.todo_folder_id
          todo.sub_todos.forEach((todo) => {
            this.sub_todos.push(todo.title)
          })
          document.getElementById('title').value = todo.todo.title
          document.getElementById('description').value = todo.todo.description
          document.getElementById('date').value = todo.todo.date
          console.log(this.folder_id)

      } catch(error) {
          console.log(error)
      }
  },
  changeFolder(value) {
      if (typeof value === typeof 1)
          this.folder_id = value
  }
},
}
</script>

<style scoped>

.sub-todo-box {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 0;
}

.sub-todo-box button {
  width: 2.5rem;
  padding-top: 0.3rem;
}
</style>