<template>
    <main>
        <header-navigation>
            <router-link :to="{name: 'add_food'}" class="header-link">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z"/></svg>
            </router-link>
        </header-navigation>
        <article class="wrapper">
            <food-item v-for="(food, i) in foods" :key="i" :food="food" />
        </article>
    </main>
</template>

<script>
import { get } from "@/api.js";
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import FoodItem from '@/components/FoodItem.vue'

export default {
  name: 'ListFood',

  components: {
    HeaderNavigation, FoodItem
  },

  data() {
    return {
      foods: [],
    }
  },

  mounted() {
    this.getFood()
  },

  methods: {
    async getFood() {
        try {
            let response = await get('food')
            this.foods = response.food
        } catch(error) {
            console.log(error)
        }
    }
  },
}
</script>

<style scoped></style>