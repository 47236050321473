<template>
    <main>
      <header-navigation title="add a todo" />

      <article class="wrapper">
        <button-input id="label" placeholder="add label" label="label" @submit="addLabel" />

        <div class="label-edit-box-wrapper">
          <div v-for="(label, i) in labels" :key="i" class="label-edit-box">
            <p>
              {{ label.title }}
            </p>
            <aside>
              <button @click="editLabel(label.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
              </button>
              <button @click="deleteLabel(label.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
              </button>
            </aside>
          </div>
        </div>
      </article>
    </main>
</template>

<script>
import ButtonInput from '@/components/ButtonInput.vue'
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import { post, get, destroy } from "@/api.js";

export default {
  name: 'IndexLabel',

  components: {
    ButtonInput, HeaderNavigation
  },

  data() {
    return {
      labels: []
    }
  },

  async mounted() {
    try {
      let response = await get('labels')
      this.labels = response.labels
    } catch(error) {
      console.log(error)
    }
  },

  methods: {
    async addLabel(value) {
      if (typeof value === typeof 'string') {
        try {
          let response = await post('label', {
            title: value
          })
          this.labels = response.labels
        } catch(error) {
          console.log(error)
        }
      }
    },
    async deleteLabel(id) {
      try {
        let response = await destroy('label/' + id)
        this.labels = response.labels
      } catch(error) {
        console.log(error)
      }
    },
    editLabel(id) {
      this.$router.push({name: 'edit_label', params: {id: id}})
    }
  },
}
</script>

<style scoped>
.label-edit-box-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.label-edit-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
}


.label-edit-box:nth-of-type(even) {
  background-color: var(--accent);
  border-radius: 1.5rem;
}

.label-edit-box aside{
  display: flex;
  gap: 1rem;
}

.label-edit-box aside button:last-of-type {
  padding-top: 0.4rem;
}

.label-edit-box aside button svg {
  width: 2.5rem;
}
</style>