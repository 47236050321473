<template>
    <main>
        <header-navigation title="add a folder"></header-navigation>
        <form @submit="send" class="form">
            <form-input name="title" :max="19" type="text" placeholder="household todos" label="choose a name" :error="titleError" :errors="titleErrors"  />
            <button type="submit">
                add todo folder
            </button>
        </form>
    </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import { post } from "@/api.js";

export default {
  name: 'AddTodoFolder',

  components: {
    HeaderNavigation, FormInput
  },

  data() {
    return {
        titleError: false,
        titleErrors: [],
    }
  },

  methods: {
    async send(ev) {
        ev.preventDefault()
        try {
            let response = await post('todo/folder', {
                title: document.getElementById('title').value,
            })
            if (response)
                this.$router.push({name: 'index_todo'})
        } catch(error) {
            console.log(error)
        }
    }
  },
}
</script>

<style scoped></style>