<template>
  <router-link v-if="set_todo !== null" :to="{name: 'view_todo', params: {id: todo.id}}" class="todo-link">
    <div class="todo-wrapper">
      <div :class="(set_todo.sub_todos.length > 0) ? 'todo extra':'todo'">
        <button :class="(set_todo.done === 1) ? 'checkbox checked':'checkbox'" @click="(ev) => check(ev, todo.id)"></button>
        <p :class="(set_todo.done === 1) ? 'checked':''">
          {{ set_todo.title }}
        </p>
      </div>
      <div v-if="set_todo.sub_todos.length > 0" class="sub-todo-view-box">
        <div v-for="(sub_todo, i) in set_todo.sub_todos" :key="i" class="todo sub-todo-box">
          <button :class="(sub_todo.done === 1) ? 'checkbox checked':'checkbox'" @click="(ev) => check(ev, sub_todo.id)" :id="'sub-'+sub_todo.id"></button>
          <p :class="(sub_todo.done === 1) ? 'checked':''">
            {{ sub_todo.title }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { put } from "@/api.js";

export default {
  name: 'ToDo',

  mounted() {
    console.log(this.$props.todo)
    this.set_todo = this.$props.todo
  },

  props: {
    todo: {
      type: Object,
    }
  },

  data() {
    return {
      set_todo: null,
    }
  },

  methods: {
    async check(ev, id) {
      ev.preventDefault()
        try {
            let response = await put('todo/check/' + id)
            console.log(response)
            if (response) {
             this.set_todo = response.todo
              setTimeout(() => {
                if (id === this.$props.todo.id)
                  ev.target.parentNode.parentNode.parentNode.classList.add('hide')
                //console.log(ev.target.parentNode.offsetHeight)
                //ev.target.parentNode.style.height = ev.target.parentNode.offsetHeight + 'px'
              }, 500)
            }
        } catch(error) {
            console.log(error)
        }
    }
  },
}
</script>

<style scoped>

.todo-wrapper {
  padding: 1.5rem 1rem 1rem;
  display: flex;
  border: 0.7px solid var(--gray);
  border-radius: 1rem;
  overflow: hidden;
  flex-direction: column;
  gap: 1rem;
}
.todo {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 0.5rem;
}

.todo.extra {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--darkgray);
}

.hide {
  height: 0px !important;
  padding: 0rem !important;
  opacity: 0;
}

.todo p {
  transition: 0.3s;
  width: calc(100% - 5rem);
}

.hide p {
  font-size: 0rem;
}

.checkbox {
  background-color: var(--dark);
  border-radius: 50%;
  aspect-ratio: 1 / 1 !important;
  width: 2.5rem !important;
  border: 0.7px solid var(--gray);
  transition: 0.3s;
  transition: 0px;
}

.checkbox.checked {
  border-color: var(--green);
  background-color: var(--accent);
}

.todo p.checked {
  text-decoration: line-through;
}

.todo.hide .checkbox {
  transform: scale(0);
}

.sub-todo-view-box {
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 0rem;

}

.sub-todo-box {
  padding: 1rem;
  gap: 1rem;
  border-radius: 1.5rem;
  border-bottom: 0;
}

.sub-todo-box p {
  line-height: 2.2rem;
  font-size: 1.4rem;
}

.sub-todo-box:nth-of-type(odd) {
  background-color: var(--accent);
}

.sub-todo-box .checkbox {
  width: 2rem !important;
}
</style>