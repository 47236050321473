<template>
    <main>
        <header-navigation title="add food" />

        <form @submit="send" class="form">
            <form-input name="name" :max="120" type="text" placeholder="parmesan" label="what food?" :error="nameError" :errors="nameErrors"  />
            <form-input name="kcal" type="text" label="how many calories?" placeholder="396kcal" :error="kcalError" :errors="kcalErrors"  />
            <form-input name="protein" type="text" label="how much protein?" placeholder="33g" :error="proteinError" :errors="proteinErrors"  />      
            <form-input name="fat" :max="120" type="text" placeholder="30g" label="how much fat?" :error="fatError" :errors="fatErrors"  />
            <form-input name="saturated_fat" type="text" label="how much saturated fat?" placeholder="20g" :error="saturatedError" :errors="saturatedError"  />
            <form-input name="carbs" type="text" label="how many carbs?" placeholder="0g" :error="carbsError" :errors="carbsErrors"  /> 
            <form-input name="sugar" :max="120" type="text" placeholder="0g" label="how much sugar?" :error="sugarError" :errors="sugarErrors"  />
            <form-input name="fibre" type="text" label="how much fibre?" placeholder="0g" :error="fibreError" :errors="fibreErrors"  />
            <form-input name="salt" type="text" label="how much salt?" placeholder="3g" :error="saltError" :errors="saltErrors"  /> 

            <form-input name="serving" type="text" label="serving" placeholder="100g" :error="servingError" :errors="servingErrors"  /> 

            <check-box id="stay" label="add multiple" />

            <button type="submit">
                add food
            </button>
        </form>
    </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import { post } from "@/api.js";

export default {
  name: 'AddFood',

  components: {
    HeaderNavigation, FormInput, CheckBox
  },

  data() {
    return {
        nameError: false,
        nameErrors: [],
        kcalError: false,
        kcalErrors: [],
        proteinError: false,
        proteinErrors: [],
        carbsError: false,
        carbsErrors: [],
        sugarError: false,
        sugarErrors: [],
        fatError: false,
        fatErrors: [],
        saturatedError: false,
        saturatedErrors: [],
        fibreError: false,
        fibreErrors: [],
        saltError: false,
        saltErrors: [],
        servingError: false,
        servingErrors: [],
    }
  },

  mounted() {
    document.getElementById('serving').value = '100g'
  },

  methods: {
    async send(ev) {
        ev.preventDefault()
        try {
            let response = await post('food', {
                name: document.getElementById('name').value,
                kcal: document.getElementById('kcal').value,
                protein: document.getElementById('protein').value,
                fat: document.getElementById('fat').value,
                saturated_fat: document.getElementById('saturated_fat').value,
                carbs: document.getElementById('carbs').value,
                sugar: document.getElementById('sugar').value,
                fibre: document.getElementById('fibre').value,
                salt: document.getElementById('salt').value,
                serving: document.getElementById('serving').value,
            })

            if (response && document.getElementById('stay').checked) {
                document.getElementById('name').value = ''
                document.getElementById('kcal').value = ''
                document.getElementById('protein').value = ''
                document.getElementById('fat').value = ''
                document.getElementById('saturated_fat').value = ''
                document.getElementById('carbs').value = ''
                document.getElementById('sugar').value = ''
                document.getElementById('fibre').value = ''
                document.getElementById('salt').value = ''
            } else {
                this.$router.go(-1)
            }
        } catch(error) {
            console.log(error)
        }
    },
  },
}
</script>

<style scoped>

</style>