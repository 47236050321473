<template>
    <article id="macros-table">
        <div class="macro-row">
            <div class="macro-column">
                <p>
                    <span>kcal:</span>
                    {{ macros.kcal }}
                </p>
            </div>
            <div class="macro-column">
                <p>
                    <span>protein:</span>
                    {{ macros.protein }}g
                </p>
            </div>
        </div>
        <div class="macro-row">
            <div class="macro-column">
                <p>
                    <span>fat:</span>
                    {{ macros.fat }}g
                </p>
            </div>
            <div class="macro-column">
                <p>
                    <span>sat. fat:</span>
                    {{ macros.saturated_fat }}g
                </p>
            </div>
        </div>
        <div class="macro-row">
            <div class="macro-column">
                <p>
                    <span>carbs:</span>
                    {{ macros.carbs }}g
                </p>
            </div>
            <div class="macro-column">
                <p>
                    <span>sugar:</span>
                    {{ macros.sugar }}g
                </p>
            </div>
        </div>
        <div class="macro-row">
            <div class="macro-column">
                <p>
                    <span>fibre:</span>
                    {{ macros.fibre }}g
                </p>
            </div>
            <div class="macro-column">
                <p>
                    <span>salt:</span>
                    {{ macros.salt }}g
                </p>
            </div>
        </div>
    </article>
</template>

<script>
export default {
  name: 'MacrosTable',

  props: {
    macros: {
        type: Object
    }
  },
}
</script>

<style scoped>
.macro-row {
    display: flex;
    justify-content: space-between;
}

.macro-row:nth-of-type(even) {
    background-color: var(--accent);
    border-radius: 1rem;
}

.macro-column {
    width: 100%;
    padding: 1rem 1rem;
}

.macro-column p {
    display: flex;
    justify-content: space-between;
}

.macro-column span {
    color: var(--gray);
    font-size: 1.6rem;
}
</style>