<template>
    <div class="input-holder">
      <label :for="name">
        {{ label }}
      </label>
      <input v-if="type !== 'description'" class="form-input" :id="name" :type="type" :placeholder="placeholder" autocomplete="off" v-model="valmodel">
      <textarea v-else class="form-input" :id="name" :placeholder="placeholder" v-model="valmodel"></textarea>
    </div>
</template>

<script>
export default {
  name: 'FormInput',

  components: {
  },

  props: {
    name: {
      defaul: '',
    },
    type: {
      defaul: '',
    },
    placeholder: {
      defaul: '',
    },
    label: {
      defaul: '',
    },
    max: {
      defaul: 128,
    },
    val: {
      default: ''
    }
  },

  data() {
    return {
      valmodel: this.$props.val
    }
  },

  watch: {
    val() {
      this.valmodel = this.$props.val
    }
  },

  methods: {

  },
}
</script>

<style scoped>
.input-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.input-holder label {
  padding-left: 1rem;
}

.form-input {
  width: 100%;
  background-color: var(--accent);
  border: 1px solid #2f2f2f;
  border-radius: 1rem;
  padding: 0.9rem 1.4rem;
  color: #757575;
}

textarea.form-input {
  min-height: 12rem;
  resize: horizontal;
}


</style>