<template>
  <main>
    <a id="logo" href="https://vorder.viktorkarpinski.com">
      <img src="./../assets/logo.svg" alt="Vorder-logo">
      Vorder
    </a>
      
    <form @submit="signup" class="auth">
      <auth-input name="email" label="your email" placeholder="contact@viktorkarpinski.com" autocomplete="off"  type="email" v-model="email" :error="(emailErrors.length > 0) ? true:false" :errors="emailErrors" />
      <auth-input name="password" label="choose a password" placeholder="#passwordStrong1" autocomplete="off" type="password" v-model="password" />
      <auth-input name="password_confirmation" label="confirm your password" placeholder="#passwordStrong1" autocomplete="off"  type="password" v-model="password_confirmation" />
      <button type="submit">
        sign up
      </button>
      <router-link :to="{name: 'login'}">
        Already have account? <span>Click here</span> to log in!
      </router-link>
    </form>
  </main>
</template>

<script>
import { post, setToken } from '@/api.js'; 
import AuthInput from '@/components/AuthInput.vue';

export default {
  name: 'SignUp',
  components: {
    AuthInput
  },

  data() {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      emailErrors: [],
    }
  },

  methods: {
    async signup(ev) {
      ev.preventDefault();
      try {
        const response = await post('signup', {
          email:  document.getElementById('email').value,
          //'password_confirmation': this.password_confirmation,
          password:  document.getElementById('password').value,
        }, false);
        const token = response.token;
        setToken(token);
        this.$router.push("/"); 
      } catch (error) {
        this.emailErrors = ['email or password is incorrect']
      }
    }
  }
}
</script>

<style scoped></style>