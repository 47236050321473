<template>
    <header>
      <button id="back" @click="$router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
        back
      </button>
      <nav>
        <slot></slot>
      </nav>
    </header>
</template>

<script>
export default {
  name: 'HeaderNavigation',

  components: {
  },

  props: {
    title: {
        type: String,
        default: ''
    }
  },
}
</script>

<style scoped>
header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 2.5rem 2rem;
  background-color: var(--dark);
  border-bottom: 1px solid var(--green);

  display: flex;
  justify-content: space-between;
  z-index: 10;
}

nav {
  display: flex;
  gap: 2rem;
}

#back {
  background: transparent;
  display: flex;
  align-items: center;
  gap: 1rem;
}

#back svg {
  fill: white;
  transform: scale(0.8);
}

@media only screen and (min-width: 800px) {
  header {
    padding-inline: 0;
  }

  #back svg {
    fill: white;
    transform: scale(0.7);
  }

  nav {
    gap: 1rem;
  }
}
</style>