<template>
    <router-link :to="{name: 'view_todo_folder', params: {id: folder.id}}" class="folder-link">
      <div>
        <p>
          {{ folder.title }}
        </p>
        <span v-if="folder.notes !== null" ref="notes">
          
        </span>
      </div>
    </router-link>
</template>

<script>
export default {
  name: 'TodoFolder',

  props: {
    folder: {
      type: Object
    }
  },

  data() {
    return {
      notes: ''
    }
  },
  mounted() {
    if (this.$props.folder.notes !== null) {
      let lines = this.$props.folder.notes.split('\n')
      for(let i = 0; i < lines.length; i++) {
        this.$refs.notes.innerHTML += lines[i]
        if (i < lines.length -1) {
          this.$refs.notes.innerHTML += '<br>'
        }
      }
    }
  }
}
</script>

<style scoped>
  .folder-link {
    width: 100%;
    padding: 2rem;
    border: 1px solid var(--gray);
    aspect-ratio: 4 / 1;
    border-radius: 1rem;
    border-top-left-radius: 0;
  }

  .folder-link span {
    display: block;
    padding: 1rem 0.5rem;
    font-style: italic;
    font-size: 1.4rem;
  }
</style>