<template>
    <main>
        <week-calendar @changed="getHabits" @load="() => { load = true }" />
        <article class="food-header">
            <h3>
                I do this:
            </h3>
            <aside>
                <router-link :to="{name: 'add_habit'}" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </router-link>
            </aside>
        </article>
        <article class="wrapper">
            <habit-tracker v-for="(habit, i) in habits" :key="i" :keyToUpdate="habit.tracker_id" :habit_tracker="habit"  />
        </article>

        <div v-if="load" id="load-wrapper">
          <div id="loader"></div>
        </div>
        <navigation-bar />
    </main>
</template>

<script>
import { get } from "@/api.js";
import WeekCalendar from '@/components/WeekCalendar.vue'
import HabitTracker from "@/components/HabitTracker.vue";
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  name: 'IndexHabit',

  components: {
    HabitTracker, WeekCalendar, NavigationBar
  },

  data() {
    return {
      load: true,
      habits: [],
    }
  },

  mounted() {
    this.getHabits()
  },

  methods: {
    async getHabits() {
        try {
            let response = await get('habits/' + this.$store.state.day.id)
            if (response) {
                this.habits = response.habits
                this.load = false
            }
        } catch(error) {
            console.log(error)
        }
    }
  },
}
</script>

<style scoped>
.food-header {
    padding: 2.5rem 1rem 2rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
}

.food-header aside {
    display: flex;
    gap: 2rem;
}
</style>