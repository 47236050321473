<template>
  <main>
      <header-navigation title="edit label"></header-navigation>
      <form @submit="send" class="form">
          <form-input :val="label.title" name="title" :max="120" type="text" placeholder="household" label="edit label" :error="titleError" :errors="titleErrors"  />
          <button type="submit">
              save changes
          </button>
      </form>
  </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import { put, get } from "@/api.js";

export default {
  name: 'EditLabel',

  components: {
    HeaderNavigation, FormInput
  },

  props: ['id'],

  data() {
    return {
        titleError: false,
        titleErrors: [],
        label: '',
    }
  },

  async mounted() {
    try {
        let response = await get('label/' + this.$props.id)
        this.label = response.label
    } catch(error) {
        console.log(error)
    }
  },

  methods: {
    async send(ev) {
        ev.preventDefault()
        try {
            let response = await put('label/' + this.$props.id, {
                title: document.getElementById('title').value,
            })
            if (response)
                this.$router.push({name: 'index_label'})
        } catch(error) {
            console.log(error)
        }
    },
  },
}
</script>