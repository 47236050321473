<template>
    <main>
        <week-calendar v-if="showCalendar" @changed="setData" @load="() => { load = true }" />
        
        <article class="food-info-wrapper">
          <macros-table v-if="showCalendar"  :macros="day" />
        </article>

        <article class="noting-wrapper">
          <div v-if="day.day_start === null" class="button-input-form-wrapper">
            <button-input label="start of day" type="time" button="set" id="day-start" :reset="false" @submit="dayStart" />
          </div>
          <p v-else class="time_noting">
            <span>start of day:</span> {{ day.day_start ? day.day_start.substring(0, 5) : '' }}
          </p>

          <div v-if="day.day_start !== null && day.eating_start === null" class="button-input-form-wrapper">
            <button-input label="first meal" type="time" button="set" id="eating-start" :reset="false" @submit="eatingStart" />
          </div>
          <p v-else class="time_noting">
            <span>first meal:</span> {{ day.eating_start ? day.eating_start.substring(0, 5) : '' }}
          </p>

          <div v-if="day.eating_start !== null && day.eating_end === null" class="button-input-form-wrapper">
            <button-input label="last meal" type="time" button="set" id="eating-end" :reset="false" @submit="eatingEnd" />
          </div>
          <p v-else class="time_noting">
            <span>last meal:</span> {{ day.eating_end ? day.eating_end.substring(0, 5) : '' }}
          </p>

          <div v-if="day.eating_end !== null && day.day_end === null" class="button-input-form-wrapper">
            <button-input label="end of day" type="time" button="set" id="day-end" :reset="false" @submit="dayEnd" />
          </div>
          <p v-else class="time_noting">
            <span>end of day:</span> {{ day.day_end ? day.day_end.substring(0, 5) : '' }}
          </p>
        </article>

        <article class="food-header">
          <h3>
              I have to do this:
          </h3>
          <aside>
              <router-link :to="{name: 'add_todo_'}" class="header-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              </router-link>
          </aside>
        </article>

        <article v-if="todos.length > 0" class="todo-wrapper">
            <to-do v-for="(todo, i) in todos" :key="i" :todo="todo" />
        </article>
        <article v-else class="todo-wrapper">
          <p>
            Today there is nothing to do
          </p>
        </article>
      
        <div v-if="load" id="load-wrapper">
          <div id="loader"></div>
        </div>

        <navigation-bar />
    </main>
</template>

<script>
import { get, post } from '@/api.js'
import WeekCalendar from '@/components/WeekCalendar.vue'
import ButtonInput from '@/components/ButtonInput.vue'
import ToDo from '@/components/ToDo.vue'
import MacrosTable from '@/components/MacrosTable.vue'
import NavigationBar from '@/components/NavigationBar.vue'


export default {
  name: 'DashBoard',
  components: {
    WeekCalendar, MacrosTable, ButtonInput, ToDo, NavigationBar
  },

  data() {
    return {
      showCalendar: false,
      day: {},
      todos: [],
      load: true
    }
  },

  mounted() {
    if (this.$store.state.day.date == null)
      this.setStartDate()
    else
      this.showCalendar = true
  },

  methods: {
    async setStartDate() {
      try {
        let response = await get('day')
        this.$store.commit('setDay', response.day)
        this.showCalendar = true
      } catch(error) {
        console.log('Dashboard, setStartOfDate', error)
      }
    },

    async setData() {
      try {
        this.day = this.$store.state.day
        let respone = await get('todos/' + this.day.date)
        if (respone) {
          this.todos = respone.todos
          this.load = false
        }
        
      } catch (error) {
        console.log('Dashboard, setData', error)
      }
    },

    dayStart() {
      try {
        let time = document.getElementById('day-start').value
        post('day/start/' + this.day.id, {
          time: time
        })
        this.day.day_start = time
      } catch(error) {
        console.log(error)
      }
    },
    dayEnd() {
      try {
        let time = document.getElementById('day-end').value
        post('day/end/' + this.day.id, {
          time: time
        })
        this.day.day_end = time
      } catch(error) {
        console.log(error)
      }
    },
    eatingStart() {
      try {
        let time = document.getElementById('eating-start').value
        post('eating/start/' + this.day.id, {
          time: time
        })
        this.day.eating_start = time
      } catch(error) {
        console.log(error)
      }
    },
    eatingEnd() {
      try {
        let time = document.getElementById('eating-end').value
        post('eating/end/' + this.day.id, {
          time: time
        })
        this.day.eating_end = time
      } catch(error) {
        console.log(error)
      }
    },
  }
}
</script>

<style scoped>

  .noting-wrapper {
    padding: 2rem 1rem;
  }

  .button-input-form-wrapper {
    margin: 1rem 0 !important;
  }
 .time_noting {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
 }

 .time_noting:nth-of-type(even) {
  background-color: var(--accent);
  border-radius: 1rem;
 }

 .time_noting span {
  font-size: 1.8rem;
  color: var(--gray);
 }

 .food-info-wrapper {
  padding: 2rem 1rem 0;
 }

 .food-header {
    padding: 0.5rem 1rem 2rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
}

.food-header aside {
    display: flex;
    gap: 2rem;
}
</style>