<template>
    <div class="input-box">
      <label :for="name">{{ label }}</label>
      <div class="input-wrapper">
        <input :type="type" :name="name" :id="name" :placeholder="placeholder" :autocomplete="autocomplete" v-model="val">
      </div>
      <p v-if="error || errors.length > 0" class="error">{{ errors[0] }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AuthInput',
  
    props: {
      label: {
        type: String,
        default: 'Label'
      },
      name: {
        type: String,
        default: 'Name'
      },
      placeholder: {
        type: String,
        default: 'placeholder'
      },
      type: {
        type: String,
        default: 'text' // Default type is text
      },
      autocomplete: {
        type: String,
        default: 'off'
      },
      error: {
        type: Boolean,
        default: false // Default error message is an empty string
      },
      errors: {
        type: Array,
        default: () => [] // Default errors is an empty array
      },
    },
  
    data() {
      return {
        val: ''
      }
    }
  }
  </script>
  
  <style scoped>
  .input-box {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
  }

  label {
    color: white;
    font-size: 2.2rem;
    padding-left: 0.5rem;
  }

  .input-wrapper {
    width: 100%;
    padding: 1px;
    background-image: url('@/assets/border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;  
}
  
  input {
    display: block;
    width: 100%;
    padding: 2rem 1rem;
    border-radius: 1rem;
    background-color: var(--accent);
    color: white;
}
  .error {
    color: var(--red); 
    text-align: center;
  }
  </style>
  