<template>
  <div>
    <!--<MoverBox :routerkey="$route.fullPath" />-->
    <router-view />
  </div>
</template>

<script>
//import { get } from '@/api.js'
//import MoverBox from "@/components/Mover.vue";

export default {
  name: "App",
  components: {
    //MoverBox, 
  },

  data() {
    return {
      token: window.localStorage.getItem('token')
    }
  },

};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Atomic+Age&display=swap');

:root {
  --dark: #111111;
  --accent: #1D1C1C;
  --gray: #7A7A7A;
  --darkgray: #2f2f2f;
  --red: #E44141;
  --green: #2B854F;
  --lightgreen: #6CC38F;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;

    text-decoration: none;
    border: 0;
    border-radius: 0;

    font-size: 62.5%;
    font-family: 'Inter', sans-serif;

    list-style-type: none !important;
    outline: none;
    color: white;
}

html, body {
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background: var(--dark);
    padding-bottom: 3rem;
}

button, a, input, label, textarea {
    cursor: pointer;
}

img {
    width: auto;
    height: auto;
}

h1 {
    font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

p, button, a, input, label, textarea {
  font-size: 1.8rem;
}

button {
  background: transparent;
}

svg {
  fill: white;
}

#load-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

#loader {
  width: 10rem;
  aspect-ratio: 1 / 1;
  border: 0.8rem solid var(--accent);
  border-radius: 50%;
  border-right-color: #2B854F;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: loading 2s infinite;

  z-index: 2000;
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* ############ LOGO ############ */

#logo {
  width: max(30rem, 100%);
  display: flex;
  flex-direction: column;
  font-family: 'Atomic Age';
  font-size: 5rem;
  align-items: center;
  padding: 0 3rem;
  margin-bottom: 4rem;
}

#logo img {
  width: 70%;
  margin-bottom: -1rem;
}

/* ############ Authentication ############ */

.auth {
  width: max(30rem, 100%);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
}

.auth button {
  background-image: linear-gradient(var(--green), #558669, var(--green));
  padding: 2rem;
  border-radius: 1rem;
  font-size: 2rem;
}

.auth a {
  font-size: 1.6rem;
  text-align: center;
}

.auth a span {
  font-size: 1.6rem;
  color: var(--lightgreen);
}

/* ############ Formulars ############ */

.form {
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  padding: 4rem 2rem;
}

.form button[type="submit"] {
  padding: 1.4rem;
  background-color: var(--green);
  border-radius: 1rem;
}

.form button[type="submit"].delete {
  background-color: var(--red);
}

/* ############ Header ############ */

.header-link {
  display: flex;
  align-items: center;
}

.header-link svg {
  fill: transparent;
}

/* ############ Todos ############ */

.todo-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.todo-wrapper h2 {
  margin-bottom: 1rem;
}

.wrapper {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media only screen and (min-width: 800px) {
  * {
    font-size: 50%;
  }

  .header-link {
    transform: scale(0.8);
  }

  body, html {
    overflow: hidden;
    padding-bottom: 0;
    height: 100vh;
  }
}

</style>
