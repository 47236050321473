<template>
  <main>
    <a id="logo" href="https://vorder.viktorkarpinski.com">
      <img src="./../assets/logo.svg" alt="Vorder-logo">
      Vorder
    </a>
      
    <form @submit="login" class="auth">
      <auth-input type="email" name="email" label="your email" placeholder="contact@viktorkarpinski.com" autocomplete="off" :error="(emailErrors.length > 0) ? true:false" :errors="emailErrors" />
      <auth-input type="password" name="password" label="your password" placeholder="#passwordStrong1" autocomplete="off" />
      <button type="submit">
        log in
      </button>
      <router-link :to="{name: 'signup'}">
        No account? Just <span>click here</span> and create one!
      </router-link>
    </form>
  </main>
</template>

<script>
import { post, setToken } from '@/api.js'; 
import AuthInput from '@/components/AuthInput.vue';

export default {
  name: 'LogIn',
  components: {
    AuthInput
  },

  data() {
    return {
      emailErrors: [],
    }
  },

  methods: {
    async login(ev) {
      ev.preventDefault();
      try {
        const response = await post('login', {
          'email': document.getElementById('email').value,
          'password': document.getElementById('password').value,
        }, false);
        const token = response.token;
        setToken(token);
        this.$router.push("/"); 
      } catch (error) {
        this.emailErrors = ['email or password is incorrect']
      }
    }
  }
}
</script>

<style scoped></style>