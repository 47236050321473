<template>
    <div class="select-input-box">
        <p>
            {{ label }}
        </p>
        <select v-if="value !== -1" :id="id" v-model="value" @change="changeEmit" >
            <option v-if="placeholder.length > 0" disabled>
                {{ placeholder }}
            </option>
            <option v-for="(item, i) in items" :key="i" :value="item[valueSelector]" :selected="(value === item[selectedSelector]) ? 'true':'false'">
                {{ item[textSelector] }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
  name: 'SelectInput',

  props: {
    id: {
        type: String,
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: 'label',
    },
    items: {
        type: Array,
    },
    valueSelector: {
        type: String,
        default: 'id',
    },
    textSelector: {
        type: String,
        default: 'title',
    },
    selectedChecker: {
        default: ''
    }, 
    selectedSelector: {
        type: String,
        default: 'title',
    },
  },

  watch: {
    selectedChecker: {
      immediate: true, // Run the handler immediately when the component is created
      handler(newVal) {
        this.value = parseInt(newVal);
      },
    },
  },

  data() {
    return {
      value: -1,
    }
  },

  async mounted() {
    this.value = await parseInt(this.$props.selectedChecker)
  },

  methods: {
    changeEmit() {
        this.$emit('change', this.value)
    }
  },
}
</script>

<style scoped>
.select-input-box {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.select-input-box p {
    padding-left: 1rem;
}
select {
    font-size: 1.8rem;
    background-color: var(--accent);
    border-radius: 1rem;
    border: 1px solid #2f2f2f;
    padding: 0.9rem 1.2rem;
    width: 100%;
}
</style>