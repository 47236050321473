<template>
    <main id="desktop-main">
      <aside class="sidebar">
        <week-calendar v-if="showCalendar" @changed="setData" @load="() => { load = true }" />
        <macros-table v-if="showCalendar"  :macros="day" />

        <article class="noting-wrapper">
          <div v-if="day.day_start === null" class="button-input-form-wrapper">
            <button-input label="start of day" type="time" button="set" id="day-start" :reset="false" @submit="dayStart" />
          </div>
          <p v-else class="time_noting">
            <span>start of day:</span> {{ day.day_start ? day.day_start.substring(0, 5) : '' }}
          </p>

          <div v-if="day.day_start !== null && day.eating_start === null" class="button-input-form-wrapper">
            <button-input label="first meal" type="time" button="set" id="eating-start" :reset="false" @submit="eatingStart" />
          </div>
          <p v-else class="time_noting">
            <span>first meal:</span> {{ day.eating_start ? day.eating_start.substring(0, 5) : '' }}
          </p>

          <div v-if="day.eating_start !== null && day.eating_end === null" class="button-input-form-wrapper">
            <button-input label="last meal" type="time" button="set" id="eating-end" :reset="false" @submit="eatingEnd" />
          </div>
          <p v-else class="time_noting">
            <span>last meal:</span> {{ day.eating_end ? day.eating_end.substring(0, 5) : '' }}
          </p>

          <div v-if="day.eating_end !== null && day.day_end === null" class="button-input-form-wrapper">
            <button-input label="end of day" type="time" button="set" id="day-end" :reset="false" @submit="dayEnd" />
          </div>
          <p v-else class="time_noting">
            <span>end of day:</span> {{ day.day_end ? day.day_end.substring(0, 5) : '' }}
          </p>
        </article>

        <article class="food-header">
            <h1>
                I ate this:
            </h1>
            <aside>
                <router-link to="/" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </router-link>
                <router-link to="/" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-coffee"><path d="M18 8h1a4 4 0 0 1 0 8h-1"></path><path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path><line x1="6" y1="1" x2="6" y2="4"></line><line x1="10" y1="1" x2="10" y2="4"></line><line x1="14" y1="1" x2="14" y2="4"></line></svg>
                </router-link>
            </aside>
        </article>

        <article v-if="food.length > 0" class="wrapper">
            <p v-for="(eaten, i) in food" :key="i">
                - {{ eaten.amount }} {{ eaten.name }}
            </p>
        </article>
        <article v-else class="wrapper">
            <p>
              - I haven't eaten yet
            </p>
        </article>
      </aside>

      <section id="main">
        <router-view />
      </section>

      <aside class="sidebar" id="habit-aside">
        <article class="food-header">
            <h1>
                I do this:
            </h1>
            <aside>
                <router-link to="/" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </router-link>
            </aside>
        </article>
        <article class="wrapper">
            <habit-tracker v-for="(habit, i) in habits" :key="i" :keyToUpdate="habit.tracker_id" :habit_tracker="habit"  />
        </article>
      </aside>
    </main>
</template>

<script>

import { get, post } from '@/api.js'
import WeekCalendar from '@/components/WeekCalendar.vue'
import ButtonInput from '@/components/ButtonInput.vue'
import MacrosTable from '@/components/MacrosTable.vue'
import HabitTracker from '@/components/HabitTracker.vue'

export default {
  name: 'DesktopDashboard',

  components: {
    WeekCalendar, MacrosTable, ButtonInput, HabitTracker,
  },

  data() {
    return {
      showCalendar: false,
      day: {},
      todos: [],
      food: [],
      habits: [],
      load: true
    }
  },

  mounted() {
    if (this.$store.state.day.date == null)
      this.setStartDate()
    else
      this.showCalendar = true
  },

  methods: {
    async setStartDate() {
      try {
        let response = await get('day')
        this.$store.commit('setDay', response.day)
        this.showCalendar = true
      } catch(error) {
        console.log('Dashboard, setStartOfDate', error)
      }
    },

    async setData() {
      try {
        this.day = this.$store.state.day
        let food_response = await get('food/eaten/' + this.day.id)
        let habit_response = await get('habits/' + this.day.id)

        if (food_response && habit_response) {
          this.food = food_response.food
          this.habits = habit_response.habits

          this.load = false
          this.day = this.$store.state.day
        }
        
      } catch (error) {
        console.log('Dashboard, setData', error)
      }
    },

    dayStart() {
      try {
        let time = document.getElementById('day-start').value
        post('day/start/' + this.day.id, {
          time: time
        })
        this.day.day_start = time
      } catch(error) {
        console.log(error)
      }
    },
    dayEnd() {
      try {
        let time = document.getElementById('day-end').value
        post('day/end/' + this.day.id, {
          time: time
        })
        this.day.day_end = time
      } catch(error) {
        console.log(error)
      }
    },
    eatingStart() {
      try {
        let time = document.getElementById('eating-start').value
        post('eating/start/' + this.day.id, {
          time: time
        })
        this.day.eating_start = time
      } catch(error) {
        console.log(error)
      }
    },
    eatingEnd() {
      try {
        let time = document.getElementById('eating-end').value
        post('eating/end/' + this.day.id, {
          time: time
        })
        this.day.eating_end = time
      } catch(error) {
        console.log(error)
      }
    },
  }
}
</script>

<style scoped>
* {
  font-size: 40%;
}
.sidebar {
  width: 42rem;
  padding: 2rem 0 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
} 

.sidebar .calendar {
  border-radius: 10px;
}

.sidebar .wrapper {
  padding: 0;
}

.sidebar .wrapper p {
  font-size: 1.6rem;
  padding-left: 2rem;
}

.food-header {
    padding: 2rem 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
}

.food-header h1 {
  font-size: 1.6rem;
}

.food-header aside {
    display: flex;
    gap: 1rem;
}
.food-header svg {
  transform: scale(0.8);
}

.button-input-form-wrapper {
  margin: 1rem 0 !important;
}
.time_noting {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  font-size: 1.8rem;
 }

.time_noting:nth-of-type(even) {
  background-color: var(--accent);
  border-radius: 1rem;
}

.time_noting span {
  font-size: 1.8rem;
  color: var(--gray);
}

#desktop-main {
  display: flex;
  gap: 3rem;
  overflow: hidden;
  height: 100vh;
}

#main {
  width: calc(100% - 90rem);
  height: 100vh;
  overflow-y: scroll;
  margin-top: 1rem;
}

#habit-aside {
  padding: 2rem 2rem 0 0;
}
</style>