<template>
    <div class="note-editor">
        <div class="note-editor-header">
            <p>
                Notes:
            </p>
            <aside class="note-editor-buttons">
                <button @click="list">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                </button>
                <button id="save" @click="save" :disabled="disabled">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                </button>
            </aside>
        </div>
        <textarea class="note-textarea" placeholder="note something..." v-model="note" @keypress="keyPressed" @keydown="keyPressed" @input="change" ref="area">
        </textarea>
    </div>
</template>

<script>
export default {
  name: 'NoteEditor',

  props: ['passed'],

  data() {
    return {
      note: null,
      initialPassed: null,
      cursorLine: 1,
      disabled: true
    }
  },

  mounted() {
    this.note = (this.$props.passed !== null) ? this.$props.passed : ''
    this.initialPassed = this.note
    this.adjustHeight()
  },

  methods: {
    list() {
        let lines = this.note.split('\n')
        let pos = this.getCursorPosition() - 1
        if (lines[pos].split('\u2022').length < 2) {
            lines[pos] = '\u2022 ' + lines[pos]
            this.note = lines.join('\n')
        }
        this.$refs.area.focus()
    },

    keyPressed(ev) {
        if (ev.keyCode == 229) {
            ev.preventDefault()
            this.note += '\u2022 '
        } 
        if (ev.keyCode === 13) {
            let pos = this.getCursorPosition() -1
            let lines = this.note.split('\n')
            let ul = false
            let enter = true

            if (lines[pos].startsWith('\u2022')) {
                if (lines[pos].length <= 2) {
                    lines[pos] = lines[pos].substring(2)
                    this.note = lines.join('\n'); 
                    enter = false
                } else {
                    ul = true
                }
            }
            if (enter) {
                lines[pos] += '\n'
                this.note = lines.join('\n'); 
                this.adjustHeight()
            }
            if (ul) {
                this.note += '\u2022 '
            }
            setTimeout(() => {this.setCursorPosition(pos+1, ul)}, 10)
            ev.preventDefault()
        }

        this.adjustHeight()
    },

    change() {        
        this.adjustHeight()
        if (this.initialPassed === this.note || this.note === '' && this.initialPassed === null) {
            this.disabled = true
        } else {
            this.disabled = false
        }
    },

    adjustHeight() {
        let area = this.$refs.area;
        let lineCount = (this.note.match(/\n/g) || []).length + 1;
        area.style.height = lineCount * 2.2 + 'rem'
    },
    getCursorPosition() {
        const textarea = this.$refs.area;
        const cursorPosition = textarea.selectionStart;
        const textBeforeCursor = this.note.substring(0, cursorPosition);
        const lines = textBeforeCursor.split('\n');
        const cursorLine = lines.length;
        this.cursorLine = cursorLine
        return cursorLine;
    },
    setCursorPosition(lineNumber, move) {
        let area = document.getElementsByClassName('note-textarea')[0]
        const lines = this.note.split('\n');
        let charCount = 0;

        for (let i = 0; i < lineNumber; i++) {
            charCount += lines[i].length + 1;
        }
        
        charCount += (move) ? 2:0

        area.focus()
        area.selectionStart = charCount;
        area.selectionEnd = charCount;
    },

    save() {
        this.initialPassed = this.note
        this.disabled = true
        this.$emit('save', this.note)
    }
  },

}
</script>

<style scoped>
.note-editor {
    background-color: var(--accent);
    border-radius: 1rem;
    padding: 2rem;
}
.note-textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    margin-top: 1rem;
    height: 2.2rem;
}

.note-editor-header {
    display: flex;
    justify-content: space-between;
}
.note-editor-buttons {
    display: flex;
    gap: 1rem;
}

.note-editor-buttons button svg {
    transform: scale(0.8);
    margin-top: 0.3rem;
}

.note-editor-buttons button:last-of-type svg {
    fill: transparent;
}

#save {
    background-color: var(--green);
    border-radius: 1rem;
    aspect-ratio: 1 / 1;
    padding: 0 0.3rem;
}

#save:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>