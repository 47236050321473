<template>
    <div class="checkbox">
        <p>
            <input type="checkbox" v-model="value" :id="id">
            {{ label }}
        </p>
        <label :for="id" :class="(value) ? 'checkbox-label checked':'checkbox-label'">
            <div class="switch"></div>
        </label>
    </div>
</template>

<script>
export default {
  name: 'CheckBox',

  components: {
  },

  props: {
    id: {
        type: String,
    },
    label: {
        type: String,
        default: 'label'
    }
  },

  data() {
    return {
      value: false,
      checked: false
    }
  },

  mounted() {

  },

  methods: {
    test() {
        console.log(this.value)
    }
  },
}
</script>

<style scoped>
.checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0rem 0 1rem;
}

.checkbox-label {
    display: flex;
    justify-content: flex-start;
    height: 4rem;
    width: 8rem;
    border-radius: 2rem;
    background-color: var(--accent);
    transition: 0.3s;
    padding: 0.5rem;
    border: 1px solid #2f2f2f;
}

.checkbox-label.checked {
    background-color: var(--green);
    justify-content: flex-end;
}

.switch {
    width: 3rem;
    border-radius: 2rem;
    background-color: white;
    transition: 0.3s;
}

.checkbox input {
    display: none;
}
</style>