<template>
  <main>
    <header-navigation title="todos">
      <router-link :to="{name: 'edit_todo', params: {id: id}}" class="header-link">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
      </router-link>
      <router-link to="/" class="header-link" @click="destroy">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
      </router-link>
    </header-navigation>
    <article v-if="!load"  class="todo-wrapper">
      <div class="todo">
        <button :class="(todo.done === 1) ? 'checkbox checked':'checkbox'" @click="check" :id="'sub-'+todo.id"></button>
        <p :class="(todo.done === 1) ? 'checked':''" contenteditable>
          {{ todo.title }}
        </p>
      </div>
      <p v-if="todo.date !== null" class="date">
        {{ todo.date }}
      </p>
      
      <div v-if="sub_todos.length > 0" class="sub-todo-view-box">
        <div v-for="(sub_todo, i) in sub_todos" :key="i" class="todo sub-todo-box">
          <button :class="(sub_todo.done === 1) ? 'checkbox checked':'checkbox'" @click="check" :id="'sub-'+sub_todo.id"></button>
          <p :class="(sub_todo.done === 1) ? 'checked':''">
            {{ sub_todo.title }}
          </p>
        </div>
      </div>

      <note-editor :passed="todo.description" @save="save" />

      <div class="time-track-header">
        <p>
          time tracked: {{ formattedDuration }}
        </p>
        <button class="track-button" :disabled="disabled" @click="track">
          track
        </button>
      </div>

        <div class="track-wrapper">
          <time-tracker v-for="(tracker, i) in time_trackers" :tracker="tracker" :index="i" :key="i" @destroy="destroyTracker" @millis="addTotalMillis" @stop="() => {disabled = false}" />
        </div>
    </article>

    <div v-if="load" id="load-wrapper">
      <div id="loader"></div>
    </div>
  </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import { get, put, post, destroy } from "@/api.js";
import TimeTracker from '@/components/TimeTracker.vue';
import NoteEditor from '@/components/NoteEditor.vue';

export default {
  name: 'ViewTodo',

  components: {
    HeaderNavigation, TimeTracker, NoteEditor
  },

  props: ['id'],

  data() {
    return {
      todo: {},
      sub_todos: [],
      time_trackers: [],
      millis: 0,
      load: true,
      disabled: false,
    }
  },

  mounted() {
    this.getTodo()
  },

  watch: {
    time_trackers: {
      handler: function() {
        console.log('watched')
        this.millis = 0;
        for(let i = 0; i < this.time_trackers.length; i++) {
          if (this.time_trackers[i].millis !== undefined)
            this.millis += this.time_trackers[i].millis
        }
      },
      deep: true
    }
  },

  computed: {
    formattedDuration() {
        return this.formatTime(this.millis);
    }
  },

  methods: {
    async getTodo() {
        try {
            let response = await get('todo/' + this.$props.id)
            if (response) {
              this.todo = response.todo
              this.sub_todos = response.sub_todos
              this.time_trackers = response.time_trackers
              this.load = false
            }
        } catch(error) {
            console.log(error)
        }
    },

    async check(ev) {
        let id = ev.currentTarget.id.split('-').pop()
        try {
          let response = await put('todo/check/' + id)
          this.sub_todos = response.todo.sub_todos
          this.todo = response.todo
        } catch(error) {
            console.log(error)
        }
    },

    async track() {
      this.disabled = true
      try {
        let response = await post('time', {
          todo_id: this.$props.id,
        })
        if (response) {
          this.time_trackers.push(response.time_tracker)
        }
      } catch(error) {
          console.log(error)
      }
    },

    async destroyTracker(id) {
        try {
          let response = await destroy('time/' + id, {})
          if (response) {
            this.time_trackers = this.time_trackers.filter(tracker => tracker.id !== id);
          }
        } catch(error) {
            console.log(error)
        }
    },

    addTotalMillis(data) {
      this.time_trackers[data.index].millis = data.diffMilliseconds
    },

    formatTime(time) {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / (1000 * 60)) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      return (
        this.pad(hours, 2) + ':' +
        this.pad(minutes, 2) + ':' +
        this.pad(seconds, 2)
      );
    },

    pad(value, width) {
        return String(value).padStart(width, '0');
    },

    async save(notes) {
      try {
        await post('todo/notes/' + this.$props.id, {
          notes: notes
        })
      } catch(error) {
        console.log(error)
      }
    },

    async destroy(ev) {
      ev.preventDefault()
      try {
        let response = await destroy('todo/' + this.$props.id)
        if (response) {
          this.$router.go(-2)
        }
      } catch(error) {
        console.log(error)
      }
    }
  },
}
</script>

<style scoped>
.todo {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.todo p {
  transition: 0.3s;
}

.todo.hide p {
  font-size: 0rem;
}

.todo p.checked {
  text-decoration: line-through;
}

.checkbox {
  background-color: var(--dark);
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 3rem;
  border: 0.7px solid var(--gray);
  transition: 0.3s;
  transition: 0px;
}

.checkbox.checked {
  border-color: var(--green);
  background-color: var(--accent);
}

.date {
  color: var(--gray);
  font-style: italic;
  padding: 0rem 5rem;
}

.description {
  padding: 2rem;
  background-color: var(--accent);
  border-radius: 1rem;
  margin: 1rem 0;
}

.description span {
  font-size: 1.6rem;
  font-style: italic;
  display: block;
  margin-bottom: 1rem;
}

.sub-todo-view-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid var(--accent);
  border-radius: 1rem;

}

.sub-todo-box {
  padding: 0.7rem 1rem;
  gap: 1.5rem;
}

.sub-todo-box .checkbox {
  width: 2.5rem;
}

.track-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.time-track-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
}

.time-track-header button {
  padding: 1rem 2rem;
  border: 1px solid var(--green);
  border-radius: 1rem;
}

.time-track-header button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>