<template>
    <div class="button-input-box">
        <label :for="id">
            {{ label }}
        </label>
        <form class="button-input-form" @submit="emitSubmit">
            <input :id="id" :placeholder="placeholder" v-model="value" :type="type" autocomplete="off">
            <button class="button-input-button" type="submit">
                {{ button }} 
            </button>
        </form>
    </div>
</template>

<script>
export default {
  name: 'ButtonInput',

  props: {
    id: {
        type: String,
        default: 'id',
    }, 
    placeholder: {
        type: String,
        default: 'placeholder',
    },
    label: {
        type: String,
        default: 'label',
    },
    type: {
        type: String,
        default: 'text'
    },
    button: {
        type: String,
        default: 'add'
    },
    reset: {
        type: Boolean,
        default: true,
    }
  },

  data() {
    return {
      value: ''
    }
  },

  methods: {
    emitSubmit(ev) {
        ev.preventDefault()
        this.$emit('submit', this.value)
        if (this.reset)
            this.value = ''
    }
  },
}
</script>

<style scoped>
.button-input-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.button-input-form {
    display: flex;
    border-radius: 1rem;
    overflow: hidden;
}

.button-input-button {
    background-color: var(--green);
    padding: 1rem 2rem !important;
    border-radius: 0 !important;
} 

.button-input-form input {
    width: 100%;
    background-color: var(--accent);
    border: 1px solid #2f2f2f;
    padding: 0.7rem 1.4rem;
    color: #757575;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.button-input-box label {
    padding-left: 1rem;
}
</style>