<template>
    <main>
        <week-calendar @changed="setData" @load="() => { load = true }" />
        <article class="food-info-wrapper">
            <macros-table :macros="day" />
        </article>
        <article class="food-header">
            <h3>
                I ate this:
            </h3>
            <aside>
                <router-link :to="{name: 'add_food'}" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </router-link>
                <router-link :to="{name: 'list_food'}" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-coffee"><path d="M18 8h1a4 4 0 0 1 0 8h-1"></path><path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path><line x1="6" y1="1" x2="6" y2="4"></line><line x1="10" y1="1" x2="10" y2="4"></line><line x1="14" y1="1" x2="14" y2="4"></line></svg>
                </router-link>
            </aside>
        </article>
        <article class="wrapper">
            <p v-for="(eaten, i) in food" :key="i">
                - {{ eaten.amount }} {{ eaten.name }}
            </p>
        </article>

        <div v-if="load" id="load-wrapper">
          <div id="loader"></div>
        </div>

        <navigation-bar />
    </main>
</template>

<script>
import { get } from "@/api.js";
import WeekCalendar from '@/components/WeekCalendar.vue'
import MacrosTable from '@/components/MacrosTable.vue'
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  name: 'IndexFood',

  components: {
    WeekCalendar, MacrosTable, NavigationBar
  },

  data() {
    return {
        load: true,
        day: {},
        food: [],
    }
  },

  mounted() {
    
  },

  methods: {
    async setData() {
      try {
        this.day = this.$store.state.day
        let respone = await get('food/eaten/' + this.day.id)
        if (respone) {
          this.food = respone.food
          this.load = false
        }
      } catch (error) {
        console.log('Dashboard, setData', error)
      }
    },
  },
}
</script>

<style scoped>
.food-info-wrapper {
  padding: 2rem 1rem 0;
}

.food-header {
    padding: 4rem 1rem 2rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
}

.food-header aside {
    display: flex;
    gap: 2rem;
}
</style>
