import "../public/service-worker.js";

import { createApp } from "vue";
import App from "./App.vue";
import DesktopDashboard from "./pages/DesktopDashboard.vue";
import { createRouter, createWebHistory } from "vue-router";
import { createStore } from "vuex";

import DashBoard from "./pages/DashBoard.vue";

import LogIn from "./pages/LogIn.vue";
import SignUp from "./pages/SignUp.vue";

import AddTodo from "./pages/Todo/AddTodo.vue";
import EditTodo from "./pages/Todo/EditTodo.vue";
import ViewTodo from "./pages/Todo/ViewTodo.vue";
import IndexTodo from "./pages/Todo/IndexTodo.vue";

import AddTodoFolder from "./pages/Todo/AddTodoFolder.vue";
import EditTodoFolder from "./pages/Todo/EditTodoFolder.vue";
import ViewTodoFolder from "./pages/Todo/ViewTodoFolder.vue";

import IndexLabel from "./pages/Label/IndexLabel.vue";
import EditLabel from "./pages/Label/EditLabel.vue";

import IndexFood from "./pages/Food/IndexFood.vue";
import AddFood from "./pages/Food/AddFood.vue";
import ListFood from "./pages/Food/ListFood.vue";

import IndexHabit from "./pages/Habit/IndexHabit.vue";
import AddHabit from "./pages/Habit/AddHabit.vue";

let routes, router;

window.onload = () => {
  if (window.innerWidth >= 800) {
    routes = [
      { path: "/", component: IndexTodo, name: "index_todo" },
      {
        path: "/todo/folder/add",
        component: AddTodoFolder,
        name: "add_todo_folder",
      },
      {
        path: "/todo/edit/:id",
        component: EditTodo,
        name: "edit_todo",
        props: true,
      },
      {
        path: "/todo/:id",
        component: ViewTodo,
        name: "view_todo",
        props: true,
      },
      {
        path: "/todo/folder/edit/:id",
        component: EditTodoFolder,
        name: "edit_todo_folder",
        props: true,
      },
      {
        path: "/todo/folder/:id",
        component: ViewTodoFolder,
        name: "view_todo_folder",
        props: true,
      },
      {
        path: "/todo/folder/edit/:id",
        component: EditTodoFolder,
        name: "edit_todo_folder",
        props: true,
      },

      {
        path: "/todo/add/:folder",
        component: AddTodo,
        name: "add_todo",
        props: true,
      },
      {
        path: "/todo/add/",
        component: AddTodo,
        name: "add_todo_",
      },
      {
        path: "/labels",
        component: IndexLabel,
        name: "index_label",
      },
      {
        path: "/label/:id",
        component: EditLabel,
        name: "edit_label",
        props: true,
      },
    ];
  } else {
    routes = [
      { path: "/", component: DashBoard, name: "dashboard" },
      { path: "/login", component: LogIn, name: "login" },
      { path: "/signup", component: SignUp, name: "signup" },

      { path: "/todos", component: IndexTodo, name: "index_todo" },
      {
        path: "/todo/add/:folder",
        component: AddTodo,
        name: "add_todo",
        props: true,
      },
      {
        path: "/todo/add/",
        component: AddTodo,
        name: "add_todo_",
      },
      {
        path: "/todo/edit/:id",
        component: EditTodo,
        name: "edit_todo",
        props: true,
      },
      {
        path: "/todo/:id",
        component: ViewTodo,
        name: "view_todo",
        props: true,
      },

      {
        path: "/todo/folder/add",
        component: AddTodoFolder,
        name: "add_todo_folder",
      },
      {
        path: "/todo/folder/edit/:id",
        component: EditTodoFolder,
        name: "edit_todo_folder",
        props: true,
      },
      {
        path: "/todo/folder/:id",
        component: ViewTodoFolder,
        name: "view_todo_folder",
        props: true,
      },

      {
        path: "/labels",
        component: IndexLabel,
        name: "index_label",
      },
      {
        path: "/label/:id",
        component: EditLabel,
        name: "edit_label",
        props: true,
      },

      {
        path: "/food",
        component: IndexFood,
        name: "index_food",
      },
      {
        path: "/food/add",
        component: AddFood,
        name: "add_food",
      },
      {
        path: "/food/list",
        component: ListFood,
        name: "list_food",
      },

      {
        path: "/habits",
        component: IndexHabit,
        name: "index_habit",
      },
      {
        path: "/habit/add",
        component: AddHabit,
        name: "add_habit",
      },
    ];
  }

  router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");
    if (to.path !== "/login" && from.path !== "/login" && !token) {
      next("/login");
    } else {
      next();
    }
  });

  const store = createStore({
    state() {
      return {
        day: {},
        desktop: false,
      };
    },
    mutations: {
      setDay(state, day) {
        state.day = day;
      },
      setDesktop(state, desktop) {
        state.desktop = desktop;
      },
    },
  });

  if (window.innerWidth >= 800) {
    store.commit("setDesktop", true);
    createApp(DesktopDashboard).use(router).use(store).mount("#app");
  } else {
    createApp(App).use(router).use(store).mount("#app");
  }
};
