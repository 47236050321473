<template>
  <main>
      <header-navigation title="add a folder"></header-navigation>
      <form @submit="send" class="form">
          <form-input :val="title" name="title" :max="19" type="text" placeholder="household todos" label="edit name" :error="titleError" :errors="titleErrors"  />
          <button type="submit">
              save changes
          </button>
      </form>
      <form @submit="destroy" class="form delete-form">
          <button type="submit" class="delete">
              delete {{ title }}?
          </button>
      </form>
  </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import { put, get, destroy } from "@/api.js";

export default {
  name: 'EditTodoFolder',

  components: {
    HeaderNavigation, FormInput
  },

  props: ['id'],

  data() {
    return {
        titleError: false,
        titleErrors: [],
        title: '',
    }
  },

  async mounted() {
    try {
            let response = await get('todos/' + this.$props.id)
            this.title = response.folder
        } catch(error) {
            console.log(error)
        }
  },

  methods: {
    async send(ev) {
        ev.preventDefault()
        try {
            let response = await put('todo/folder/' + this.$props.id, {
                title: document.getElementById('title').value,
            })
            if (response)
                this.$router.push({name: 'view_todo_folder', params: {id: this.$props.id}})
        } catch(error) {
            console.log(error)
        }
    },
    async destroy(ev) {
        ev.preventDefault()
        try {
            let response = await destroy('todo/folder/' + this.$props.id)
            if (response)
                this.$router.push({name: 'index_todo'})
        } catch(error) {
            console.log(error)
        }
    },
  },
}
</script>

<style scoped>
.delete-form {
  padding-top: 0;
  margin-top: -2rem;
}
</style>