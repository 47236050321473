import axios from "axios";

//const baseURL = "http://127.0.0.1:8000/api/";
const baseURL = "https://api.viktorkarpinski.com/api/";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const token = localStorage.getItem("token") || "";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
});

export const post = async (url, data, logged = true) => {
  if (!logged) {
    axiosInstance.defaults.headers.Authorization = undefined;
  }
  const response = await axiosInstance.post(url, data);
  return response.data;
};

export const get = async (url, logged = true) => {
  console.log(url, token);
  if (!logged) {
    axiosInstance.defaults.headers.Authorization = undefined;
  }
  const response = await axiosInstance.get(url);
  return response.data;
};

export const put = async (url, data, logged = true) => {
  if (!logged) {
    axiosInstance.defaults.headers.Authorization = undefined;
  }
  const response = await axiosInstance.put(url, data);
  return response.data;
};

export const destroy = async (url, data, logged = true) => {
  if (!logged) {
    axiosInstance.defaults.headers.Authorization = undefined;
  }
  const response = await axiosInstance.delete(url, data);
  return response.data;
};

export const setToken = (newToken) => {
  localStorage.setItem("token", newToken);
};

export default axiosInstance;
