<template>
    <div class="food-item-wrapper">
      <div class="food-item-info">
        <p>
          {{ food.name }}
        </p>
        <span>
          {{ food.serving }}
        </span>
      </div>
      <form @submit="eat" class="food-item-input-box">
        <input class="food-item-input" type="text" placeholder="1.3" :id="food.id" v-model="factor" autocomplete="off">
      </form>
    </div>
</template>

<script>
import { post } from "@/api.js";

export default {
  name: 'FoodItem',

  props: {
    food: {
      type: Object
    }
  },

  data() {
    return {
      factor: ''
    }
  },

  mounted() {
  },

  methods: {
    async eat(ev) {
      ev.preventDefault()
      try {
        let respone = await post('food/eat/' + this.food.id +'/'+ this.$store.state.day.id, {
          factor: parseFloat(this.factor),
        })
        console.log(respone)
      } catch(error) {
        console.log(error)
      }
    }
  },
}
</script>

<style scoped>
.food-item-wrapper {
  padding: 1rem 1rem 1rem 1.5rem;
  background-color: var(--accent);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.food-item-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.food-item-info span {
  font-size: 1.6rem;
  color: var(--gray);
}

.food-item-input {
  background-color: var(--dark);
  border-radius: 1rem;
  padding: 1rem;
  width: 8rem;
  text-align: center;
  border: 1px solid var(--gray);
}
</style>