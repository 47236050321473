<template>
    <main>
        <header-navigation title="add a habit" />

        <form @submit="send" class="form">
            <form-input name="name" :max="120" type="text" placeholder="I take a cold shower" label="what is your habit?" :error="nameError" :errors="nameErrors"  />
            <form-input name="description" type="description" label="description of what you are doing" placeholder="I am getting money" :error="descriptionError" :errors="descriptionErrors"  /> 
            
            <check-box id="stay" label="is a counter" />

            <button type="submit">
                add habit
            </button>
        </form>
    </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import { post } from "@/api.js";

export default {
  name: 'AddHabit',

  components: {
    HeaderNavigation, FormInput, CheckBox
  },

  data() {
    return {
        nameError: false,
        nameErrors: [],
        descriptionError: false,
        descriptionErrors: [],
    }
  },

  mounted() {
  },

  methods: {
    async send(ev) {
        ev.preventDefault()
        try {
            let response = await post('habit', {
                name: document.getElementById('name').value,
                description: document.getElementById('description').value,
                repeat: 1,
                counter: 0,
                amount: 1,
            })

            if (response)
                this.$router.go(-1)
        } catch(error) {
            console.log(error)
        }
    },
  },
}
</script>

<style scoped>


</style>