<template>
    <main>
      <header-navigation title="todos">
        <router-link :to="{name: 'edit_todo_folder', params: {id: id}}" class="header-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
        </router-link>
        <router-link :to="{name: 'add_todo', params: {folder: id}}" class="header-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
        </router-link>
      </header-navigation>
      
      <article v-if="!load" class="todo-wrapper">
        <h2>
          {{ folder.title }}
        </h2>
        <note-editor :passed="folder.notes" @save="save" />
        <to-do v-for="(todo, i) in todos" :key="i" :todo="todo" />
      </article>
      <div v-if="load" id="load-wrapper">
        <div id="loader"></div>
      </div>
    </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import NoteEditor from "@/components/NoteEditor.vue";
import ToDo from "@/components/ToDo.vue";
import { get, post } from "@/api.js";

export default {
  name: 'ViewTodoFolder',

  components: {
    HeaderNavigation, ToDo, NoteEditor
  },

  props: ['id'],

  data() {
    return {
      todos: [],
      folder: {note: ''},
      load: true,
    }
  },

  mounted() {
    this.getTodos()
  },

  methods: {
    async getTodos() {
      try {
        let response = await get('todos/get/folder/' + this.$props.id)
        if (response) {
          this.todos = response.todos
          this.folder = response.folder
          this.load = false
        }
      } catch(error) {
          console.log(error)
      }
    },

    async save(notes) {
      try {
        let response = await post('todo/folder/' + this.$props.id, {
          notes: notes
        })
        if (response) {
          this.folder.notes = notes
        }
      } catch(error) {
          console.log(error)
      }
    }
  },
}
</script>

<style scoped></style>