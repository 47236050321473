<template>
    <article class="calendar">
    <div class="week-box">
      <aside>
        <button id="prev" @click="previous">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
        </button>
        <p>
          {{ week }}
        </p>
        <button id="next" @click="next">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
        </button>
      </aside>
      <p>
        {{ date }}
      </p>
    </div>
     <div class="day-box">
      <button v-for="(day, i) in days" :key="i" :class="(dates[i] === active.date) ? 'day active' : 'day'" @click="changeDate(dates[i])">
        {{ day.substring(0, 2) }}
      </button>
     </div>
    </article>
</template>

<script>
import { get } from '@/api.js'

export default {
  name: 'WeekCalendar',

  components: {
  },

  data() {
    return {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dates: ['', '', '', '', '', '', ''],
      active: '',
      week: '',
      date: ''
    }
  },

  async created() {
    this.changeDate(this.$store.state.day.date)
  },

  watch: {
    active() {
      const datesOfWeek = this.getWeekDates(this.active.date);
      datesOfWeek.forEach((date, i) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        this.dates[i] = `${year}-${month}-${day}`;
      });
      this.week = this.dates[0].substring(8, 10) + ', ' +  this.dates[0].substring(5, 7) + ' - ' + this.dates[6].substring(8, 10) + ', ' +  this.dates[6].substring(5, 7)
      this.date = this.active.date.substring(8, 10) + ', ' +  this.active.date.substring(5, 7) + ' ' + this.active.date.substring(0, 4)
    },
  },

  methods: {
    getWeekDates(startDateString) {
      const startDate = new Date(startDateString);

      const currentDay = startDate.getDay();

      startDate.setDate(startDate.getDate() - currentDay);

      const weekDates = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        weekDates.push(date);
      }

      return weekDates;
    }, 

    async changeDate(newDate) {
      this.$emit('load')
      try {
        let response = await get('day/' + newDate)
        if (response) {
          this.$store.commit('setDay', response.day)
          this.active = this.$store.state.day
          this.$emit('changed', this.active)
        }
      } catch(error) {
        console.log(error)
      }
    },

    getDateAWeekEarlier(dateString, next) {
      const givenDate = new Date(dateString);
      givenDate.setDate(givenDate.getDate() - (7 * (next ? -1:1) ) );

      const year = givenDate.getFullYear();
      const month = (givenDate.getMonth() + 1).toString().padStart(2, '0');
      const day = givenDate.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    previous() {
      this.changeDate(this.getDateAWeekEarlier(this.active.date, false))
    },

    next() {
      this.changeDate(this.getDateAWeekEarlier(this.active.date, true))
    }
  },
}
</script>

<style scoped>
.calendar {
  background-color: var(--accent);
  position: relative;
  z-index: 3000;
}

.day-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 2rem;
}

.day {
  border-radius: 50%;
  width: 4.5rem;
  aspect-ratio: 1 / 1;
  background-color: var(--accent);
  padding: 1rem;
  border: 2px solid var(--dark);
  font-size: 1.5rem;
}

.active {
  border-color: var(--green);
}

.week-box {
  display: flex;
  padding: 2rem 2rem 1.5rem 1rem;
  justify-content: space-between;
}

.week-box aside {
  display: flex;
}

#prev, #next {
  width: 3rem;
}

#prev svg, #next svg {
  fill: transparent;
}

#prev svg {
  transform: rotate(90deg);
}

#next svg {
  transform: rotate(-90deg);
}
</style>