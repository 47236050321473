<template>
    <main>
        <header-navigation title="add a todo" />

        <form @submit="send" class="form">
            <form-input name="title" :max="120" type="text" placeholder="rent has to be paid" label="what is there to do?" :error="titleError" :errors="titleErrors"  />
            <form-input name="date" type="date" label="when is it to do?" :error="dateError" :errors="dateErrors"  />
            <form-input name="description" type="description" label="any notes?" placeholder="get money" :error="descriptionError" :errors="descriptionErrors"  />        
        
            <button-input id="sub-todo" placeholder="sub-todo" label="any sub-todos?" @submit="addSubTodo" />

            <div v-if="sub_todos.length > 0" class="sub-todo-box-wrapper">
                <div v-for="(sub_todo, i) in sub_todos" :key="i" class="sub-todo-box">
                    <button @click="deleteSubTodo" :id="'sub-'+i">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                    </button>
                    <p>
                        {{ sub_todo }}
                    </p>
                </div>
            </div>

            <select-input id="folder" :selectedChecker="folder" selectedSelector="id" label="what folder?" :items="folders" placeholder="select a folder" valueSelector="id" textSelector="title" @change="changeFolder" />

            <div class="label-box-wrapper">
                <p>
                    select labels
                    <router-link :to="{name: 'index_label'}" class="header-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z"/></svg>
                    </router-link>
                </p>
                <div class="label-box">
                    <label v-for="label in labels" :key="label.id+'-label'" :class="(label_ids.includes(label.id)) ? 'label checked':'label'">
                        <input type="checkbox" :id="label.id+'-label'" @click="selectLabel(label.id)">
                        {{ label.title }}
                    </label>

                </div>
            </div>
            
            <check-box id="stay" label="add multiple todos" />

            <button type="submit">
                add todo
            </button>
        </form>
    </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FormInput from "@/components/FormInput.vue";
import ButtonInput from "@/components/ButtonInput.vue";
import SelectInput from "@/components/SelectInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import { post, get } from "@/api.js";

export default {
  name: 'AddTodo',

  components: {
    HeaderNavigation, FormInput, ButtonInput, SelectInput, CheckBox
  },

  props: ['folder'],

  data() {
    return {
        titleError: false,
        titleErrors: [],
        dateError: false,
        dateErrors: [],
        descriptionError: false,
        descriptionErrors: [],

        sub_todos: [],
        sub_todo: '',

        folder_id: '',
        folders: [],

        labels: [],
        label_ids: []
    }
  },

  mounted() {
    this.folder_id = this.$props.folder
    this.getFoldersAndLabels()
  },

  methods: {
    async send(ev) {
        ev.preventDefault()
        try {
            let subs = this.sub_todos.map(sub_todo => {return sub_todo}).join('§')
            let response = await post('todo', {
                title: document.getElementById('title').value,
                date: document.getElementById('date').value,
                description: document.getElementById('description').value,
                folder_id: this.folder_id,
                sub_todos: subs
            })

            if (response && document.getElementById('stay').checked) {
                document.getElementById('title').value = ''
                document.getElementById('description').value = ''
                this.sub_todo = ''
                this.sub_todos = []
            } else {
                this.$router.go(-1)
            }
        } catch(error) {
            console.log(error)
        }
    },
    addSubTodo(value) {
        if (typeof value === typeof 'string')
            this.sub_todos.unshift(value)
    },
    deleteSubTodo(ev) {
        ev.preventDefault()
        this.sub_todos.splice(this.sub_todos.indexOf(this.sub_todos[ev.currentTarget.id.split('-')[1]]), 1)
    },
    async getFoldersAndLabels() {
        try {
            let folders = await get('folders')
            this.folders = folders.folders

            let labels = await get('labels')
            this.labels = labels.labels
        } catch(error) {
            console.log(error)
        }
    },
    changeFolder(value) {
        if (typeof value === typeof 1)
            this.folder_id = value
    },
    selectLabel(id) {
        if (this.label_ids.includes(id)) {
            this.label_ids.splice(this.label_ids.indexOf(id), 1);
        } else {
            this.label_ids.push(id)
        }
        console.log(this.label_ids)
    }
  },
}
</script>

<style scoped>

.sub-todo-box {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem 0;
}

.sub-todo-box button {
    width: 2.5rem;
    padding-top: 0.3rem;
}

.label-box-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.label-box-wrapper p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label-box {
    display: flex;
    flex-wrap: wrap;
    border-radius: 1.5rem;
    background-color: var(--accent);
    border: 1px solid var(--darkgray);
    padding: 1rem;
    gap: 1rem;
}

.label {
    padding: 0.5rem 1rem;
    border: 1px solid var(--darkgray);
    border-radius: 1rem;
    display: inline-block;
    transition: 0.3s;
}

.label.checked {
    background-color: var(--green);
}

.label input {
    display: none;
}
</style>