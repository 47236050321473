<template>
    <button class="habit-tracker" @click="complete">
      <p>
        {{ habit.name }}
      </p>
      <div :class="(habit.complete === 1) ? 'tracker complete':'tracker'"></div>
    </button>
</template>

<script>
import { post } from "@/api.js";

export default {
  name: 'HabitTracker',

  props: {
    habit_tracker: {
      type: Object
    },
    keyToUpdate: {
      type: Number
    }
  },

  watch: {
    keyToUpdate() {
        this.habit = this.$props.habit_tracker;
    }
  },

  data() {
    return {
      habit: {}
    }
  },

  async mounted() {
    this.habit = this.$props.habit_tracker
  },

  methods: {
    async complete() {
      try {
        let response = await post('habit/check/' + this.habit.tracker_id)
        if (response) {
          this.habit.complete = (this.habit.complete === 0) ? 1:0
        }
      } catch(error) {
        console.log(error)
      }
    }
  },
}
</script>

<style scoped>
.habit-tracker {
  padding: 2rem 2rem;
  border-radius: 1rem;
  background-color: var(--accent);
  position: relative;
  overflow: hidden;
}

.habit-tracker p {
  text-align: left;
}

.tracker {
  width: 0%;
  height: 100%;
  background-color: var(--green);
  opacity: 0.5;
  transition: 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.tracker.complete {
  width: 100%;
}

</style>