<template>
    <main>
        <header-navigation v-if="!$store.state.desktop" title="todos">
            <router-link :to="{name: 'add_todo_folder'}" class="header-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </router-link>
        </header-navigation>
        <article class="todo-wrapper">
            <h2 class="todo-heading">
                Today

                <router-link :to="{name: 'add_todo_folder'}" class="header-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </router-link>
            </h2>
            <to-do v-for="(todo, i) in todos" :key="i" :todo="todo" />
        </article>
        <h2 class="folder-heading">
            Folders
        </h2>
        <article class="todo-folder-wrapper">
            <todo-folder v-for="(folder, i) in folders" :key="i" :folder="folder" />
        </article>

        <navigation-bar v-if="!$store.state.desktop" />
    </main>
</template>

<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import TodoFolder from "@/components/TodoFolder.vue";
import ToDo from "@/components/ToDo.vue";
import { get } from "@/api.js";
import NavigationBar from '@/components/NavigationBar.vue';

export default {
  name: 'IndexTodo',

  components: {
    HeaderNavigation, TodoFolder, ToDo, NavigationBar
  },

  data() {
    return {
        todos: [],
        folders: [],
    }
  },

  mounted() {
    this.getTodos()
    this.getFolders()
  },

  methods: {
    async getTodos() {
        try {
            let response = await get('todos')
            this.todos = response.todos
        } catch(error) {
            console.log(error)
        }
    },
    async getFolders() {
        try {
            let response = await get('todo/index/folders')
            this.folders = response.todo_folders
        } catch(error) {
            console.log(error)
        }
    },
  },
}
</script>

<style scoped>

.folder-heading {
    margin: 2rem 2rem 0rem;
}
.todo-folder-wrapper {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
} 

.todo-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>