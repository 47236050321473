<template>
    <div class="tracker">
        <p class="time-date">
            {{ tracker.from.slice(0, -3) }} - {{ tracker.till ? tracker.till.slice(0, -3) : '...' }} <br>
            <span>
                {{ tracker.date }}
            </span>
        </p>
            
        <p v-if="tracker.till === null" class="time">
            {{ formattedTime }}
        </p>

        <p v-else class="time">
            {{ formattedDuration }}
        </p>

        <aside>
            <button v-if="tracker.till === null" class="timer-button stop-button" @click="stopTracking">
                <div class="circle"></div>
            </button>
            <button v-else class="timer-button" @click="destroy">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
            </button>
        </aside>
    </div>
</template>

<script>
import { put } from "@/api.js";

export default {
  name: 'TimeTracker',

  components: {
  },

  props: ['tracker', 'index'],

  data() {
    return {
        running: false,
        startTime: null,
        elapsedTime: 0,
        formattedTime: '00:00:00'
    }
  },

  mounted() {
    if (this.tracker.till === null) {
        this.startTracking()
    }
  },

  computed: {
    formattedDuration() {
      if (this.tracker.till !== null) {
        const fromTime = new Date(this.tracker.date + ' ' + this.tracker.from);
        const tillTime = new Date(this.tracker.date + ' ' + this.tracker.till);
        const diffMilliseconds = tillTime - fromTime;

        let index = this.$props.index
        this.$emit('millis', {diffMilliseconds, index})
        return this.formatTime(diffMilliseconds);
      }
      return '...';
    }
  },

  methods: {
    startTracking() {
      if (!this.running) {
        this.startTime = Date.now() - this.calculateElapsedTime(this.tracker.from);
        this.running = true;
        this.update();
      }
    },

    async stopTracking() {
      if (this.running) {
        this.elapsedTime = 0;
        this.running = false;
        this.formattedTime = '00:00:00';
        try {
          let response = await put('time/' + this.$props.tracker.id, {})
          if (response) {
            this.$props.tracker.till = response.time
            this.$emit('stop')
          }
        } catch(error) {
            console.log(error)
        }
      }
    },

    destroy() {
      this.$emit('destroy', this.$props.tracker.id)
    },

    update() {
      if (this.running) {
        this.elapsedTime = Date.now() - this.startTime;
        this.formattedTime = this.formatTime(this.elapsedTime);
        setTimeout(this.update, 1000);
      }
    },

    formatTime(time) {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / (1000 * 60)) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      return (
        this.pad(hours, 2) + ':' +
        this.pad(minutes, 2) + ':' +
        this.pad(seconds, 2)
      );
    },

    calculateElapsedTime(from) {
        const [fromHours, fromMinutes, fromSeconds] = from.split(':').map(Number);

        const currentTime = new Date();

        const providedTime = new Date();
        providedTime.setHours(fromHours, fromMinutes, fromSeconds, 0);
        let elapsedTime = currentTime.getTime() - providedTime.getTime();

        if (elapsedTime < 0) {
            elapsedTime += 24 * 60 * 60 * 1000;
        }

        return elapsedTime
    },

    pad(value, width) {
        return String(value).padStart(width, '0');
    },
  },
}
</script>

<style scoped>
.tracker {
    padding: 0 0 0 1rem;
    border: 1px solid var(--gray);
    border-radius: 1rem;
    overflow: hidden;

    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.tracker .time-date {
    width: 30%;
    font-size: 1.6rem;
}

.tracker .time-date span {
    font-size: 1.4rem;
    font-style: italic;
    color: var(--gray);
    margin-top: 0rem;
    display: block;
}

.tracker .time {
    font-size: 1.8rem;
    color: var(--lightgreen);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 2rem;
}

.tracker aside {
    justify-self: flex-end;
    height: 5rem;
    
    display: flex;
}

.timer-button {
    aspect-ratio: 1 / 1;
    display: block;
    height: 100%;
}

.timer-button.stop-button {
    background-color: var(--red);
}

.timer-button svg {
    fill: none;
}

.circle {
    width: 1rem;
    aspect-ratio: 1 / 1;
    background-color: white;
    border-radius: 50%;
    margin: auto;
}

@media only screen and (min-width: 800px) {
    .timer-button svg {
        transform: scale(0.8);
    }
}
</style>